<template>
  <div @keydown="login" class="page-login" v-loading="loading">
    <video class="login-bg" autoplay muted loop>
      <source src="@/assets/login/bg.mp4" type="video/mp4" />
    </video>
    <div class="login-wrap">
      <div class="login-title">济源无人机应用服务平台</div>
      <div class="login-body">
        <div class="login-body__logo">
          <img src="~@/assets/login/logo2.png" alt="logo" />
        </div>
        <div class="login-body__form">
          <div class="form-title">账户密码登录</div>
          <div class="form-item">
            <div class="form-item__icon">
              <span class="iconfont icon-zhanghao"></span>
            </div>
            <div class="form-item__input">
              <el-input v-model="form.username" placeholder="请输入账号" clearable />
            </div>
          </div>
          <div class="form-item">
            <div class="form-item__icon">
              <span class="iconfont icon-mima"></span>
            </div>
            <div class="form-item__input">
              <el-input type="password" v-model="form.password" placeholder="请输入密码" clearable
                autocomplete="new-password" />
            </div>
          </div>
          <div class="jcsb form-item__input  ">
            <el-input class="codeStyle" v-model="code" placeholder="请输入验证码" clearable />
            <div @click="refresh">
              <Identify :identifyCode="identifyCode" ref="Identify"></Identify>
            </div>
          </div>
          <div class="form-btns mt10">
            <el-button @click="handleLogin" class="form-btn">登录</el-button>
          </div>
          <div class="form-btns" style="text-align:right;">
            <el-button @click="tobackstage" class="foot-btns">后台管理</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { VUE_APP_WS_IP: WS_IP } = process.env;
import { mapActions, mapGetters } from "vuex";
import Identify from "@/components/identify/index.vue";
import _ from "lodash";
export default {
  data() {
    return {
      loading: false,
      identifyCode: "",
      code: "",
      form: {
        // username: "admin",
        // password: "mmcycscszy2022",
        username: null,
        password: null,
        remember: false,
      },
    };
  },

  components: { Identify },
  created() {
    this.refresh()
  },
  methods: {
    ...mapActions("user", ["Login", "Mylayers"]),
    refresh() {
      let str = "QWERTYUIOPASDFGHJKLZXCVBNM1234567890";
      let code = "";
      for (let i = 0; i < 4; i++) {
        //0-1的随机小数 -->  0~数组长度-1的范围   取整
        let randPosition = Math.floor(Math.random() * (str.length - 1));  //每次生成一个随机数的位置
        code += str[randPosition];//带有随机位置作为下标，指示到当前随机产生的某个字符上，
      }
      this.identifyCode = code
    },
    login(e) { // 回车登录事件
      if (e.keyCode === 13) {
        this.handleLogin()
      }
    },
    async handleLogin() {
      let { username, password } = this.form;
      let { code, identifyCode } = this;
      if (_.isEmpty(username)) {
        return this.$message.error("请输入账号");
      }
      if (_.isEmpty(password)) {
        return this.$message.error("请输入密码");
      }
      if (!code) {
        return this.$message.error("请输入验证码");
      }
      if (code != identifyCode) {
        this.$message.error("验证码错误!");
        return this.refresh()
      }

      let LoginStatus = await this.Login(this.form)
      //初始化底图
      this.Mylayers('tian')
      if (LoginStatus.status == 1) {
        localStorage.setItem('user_key', btoa(JSON.stringify(this.form)))
        this.$el_message(
          "登录成功",
          () => {
            this.$router.replace("/").catch(() => { });
          },
          "success",
          1000
        );
      } else {
        this.$message.error(LoginStatus.message)
      }
      this.loading = false;
    },
    tobackstage() {
      window.open(WS_IP + '/hawk_back/#/home/index')
    }
  },
};
</script>

<style lang="scss" scoped>
.page-login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-bg {
    background: #000;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -100;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  .login-wrap {
    width: 924px;
    height: 545px;
    background: center center url(~@/assets/login/bg.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;

    .login-title {
      height: 70px;
      line-height: 70px;
      text-align: center;
      font-family: PangMenZhengDao;
      font-size: 34px;
      color: #e0e6f2;
      letter-spacing: 6.29px;
      font-weight: 400;
      background-image: linear-gradient(180deg, #ffffff 0%, #93ceff 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
    }

    .login-body {
      box-sizing: border-box;
      padding: 20px 76px 0;
      display: flex;
      align-items: center;

      &__logo {
        width: 290px;
        height: 290px;
        margin-right: 60px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__form {
        box-sizing: border-box;
        /* border: 1px solid red; */
        flex: 1;

        .form-title {
          font-size: 22px;
          color: #0094ff;
          font-weight: 400;
          margin-bottom: 24px;
        }

        .form-item {
          height: 64px;
          box-sizing: border-box;
          margin-bottom: 24px;
          border: 1px solid #0871d1;
          display: flex;
          align-items: center;
          padding: 0 12px;

          &:last-child {
            margin-bottom: 0;
          }

          &__icon {
            width: 33px;
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            flex-shrink: 0;

            .iconfont {
              color: #135eae;
              font-size: 40px;
            }
          }

          &__input {
            flex: 1;

            ::v-deep .el-input {
              .el-input__inner {
                background: transparent;
                border: none;
                font-size: 16px;
                color: #ffffff;
                letter-spacing: 0;
                font-weight: 400;
              }
            }
          }
        }

        .form-btns {
          .form-btn {
            width: 100%;
            border: none;
            border-radius: 0;
            height: 50px;
            background: #0b5fab;
            font-family: PangMenZhengDao;
            font-size: 22px;
            color: #ffffff;
            text-align: center;
            font-weight: 400;

            &:hover {
              background: #074883;
            }
          }

          .foot-btns {
            width: 30%;
            border: none;
            border-radius: 0;
            height: 50px;
            background: #0b5fab;
            font-family: PangMenZhengDao;
            font-size: 22px;
            color: #ffffff;
            text-align: center;
            font-weight: 400;
            margin-top: 20px;

            &:hover {
              background: #074883;
            }
          }
        }
      }
    }
  }

  .codeStyle {
    border: 1px solid #0871d1 !important;
    margin-right: 20px;
    height: 60px;
    line-height: 60px;
  }
}
</style>
